/* MOBILE DEVICE DETECTION  */
window.isMobile = false;

(function () {
  function widthChange(mq) {
    if (!mq.matches) {
      window.isMobile = true;
    }
  }
  if (typeof(matchMedia) !== 'undefined' && matchMedia) {
    const mq = window.matchMedia('(min-width: 768px)');
    mq.addListener(widthChange);
    widthChange(mq);
  }
}());
