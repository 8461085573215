if(!String.prototype.startsWith){
    String.prototype.startsWith = function (str) {
        return !this.indexOf(str);
    }
}

var launchMdmEvent = function(container, category, action, label) {
    try {
      mdm_events(container, this, 'track-event', {'event_category':category,'event_action':action,'event_label':label});
    } catch(e) {
      console.error(e);
      if (typeof(Raven) !== 'undefined') {
        Raven.captureMessage('no tracking : mdm_events wasn\'t there');
      }
    }
    return;
};

var initMdmRender = function(elem) {
    var data = elem.getAttribute('data-tracking').split('|');
    var category = data[0] != 'undefined' ? data[0] : '';
    var action = data[1] != 'undefined' ? data[1] : '';
    var label = data[2] != 'undefined' ? data[2] : '';
    var non_interactive = data[3] != 'undefined' ? data[3] : '';
    var param = {'event_category':category,'event_action':action,'event_label':label};
    var container = 3;

    if (non_interactive != '') {
        param['event_is_non_interactive'] = non_interactive;
    }

    if (typeof(getTcId) !== 'undefined'){
        container = getTcId();
    } else if (typeof(tc_container_id) !== 'undefined') {
        container = tc_container_id;
    }
    return mdm_events(container, this, 'track-event', param);
};

var initMdmRenderTrendingBook = function (elem) {
    var data = elem.getAttribute('data-tracking').split('|');
    var product_id = data[0] != 'undefined' ? data[0] : '';
    var product_name = data[1] != 'undefined' ? data[1] : '';
    var product_category = data[2] != 'undefined' ? data[2] : '';
    var product_brand = data[3] != 'undefined' ? data[3] : '';
    var product_variant = data[4] != 'undefined' ? data[4] : '';
    var product_totalprice_ati = data[5] != 'undefined' ? data[5] : '';
    var product_activity = data[6] != 'undefined' ? data[6] : '';
    var product_style = data[7] != 'undefined' ? data[7] : '';
    var product_room = data[8] != 'undefined' ? data[8] : '';
    var product_position = data[9] != 'undefined' ? data[9] : '';
    var list_name = data[10] != 'undefined' ? data[10] : '';
    var non_interactive = data[11] != 'undefined' ? data[11] : '';

    var param = {
        'product_id': product_id,
        'product_name': product_name,
        'product_category': product_category,
        'product_brand': product_brand,
        'product_variant': product_variant,
        'product_totalprice_ati': product_totalprice_ati,
        'product_activity': product_activity,
        'product_style': product_style,
        'product_room': product_room,
        'product_position': product_position,
        'list_name': list_name
    };

    var container = 3;

    if (non_interactive != '') {
        param['event_is_non_interactive'] = non_interactive;
    }

    if (typeof tc_container_id != 'undefined') {
        container = tc_container_id;
    }
    return mdm_events(container, this, 'product_list_click', param);
};



var slugify = function (text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[^\w\-]+/g, '')
        .replace(/\_\_+/g, '_')
        .replace(/^_+/, '')
        .replace(/_+$/, '');
};

    
window.addEventListener('load', function(event) {
    var elToTrack = $('*[data-mdm-track]');

    $.each(elToTrack, function () {
        var triggerAction = $(this).data('mdm-track-action');
        var dataPrefix = $(this).data('mdm-track-data-prefix');
        var tmp = $(this).data('mdm-track').split(':');

        var containerId = tmp[1];
        var tracker = "track-" + tmp[0];
        var options = [];
        if(dataPrefix == undefined || dataPrefix == ''){
            dataPrefix = tmp[0];
        }
        $.each($(this).data(), function (a, b) {
            if (a.startsWith('trackerData')) {
                key = dataPrefix + '_' + a.replace('trackerData', '').toLowerCase();
                options[key] = b;
            }
        });
        $(this).on(triggerAction, function(){
            mdm_events(containerId,this,tracker,options)
        });
    });
}, {passive: true});

/**
 * trackingManager from tracking-event-bundle
 *
 */
document.addEventListener('DOMContentLoaded', function(event) {

    //mouseleave event on the first level elements of navigation
    $('header').on(
        'mouseleave',
        '.primary-nav .first-level > li[data-tracking], .primary-nav div[data-tracking].title,  .secondary-nav .account-item',
        function() {
            initMdmRender(this);
        }
    );

    $('[data-tracking]:not(li.list-product-to-popin > a[data-tracking])').on('click', function() {
        initMdmRender(this);
    });
    $('li.list-product-to-popin > a[data-tracking]').on('click', function() {
        initMdmRenderTrendingBook(this);
    });
    if ($('[data-track-errors]').length > 0) {
        var trackErrors = setInterval(function(){
            if (typeof mdm_events == 'function') {
                initMdmRender($('[data-track-errors]').get(0));
                clearInterval(trackErrors);
            }
        }, 300);
    }
}, {passive:true});
