window.addEventListener('load', () => {
  svg4everybody();
}, { passive: true });

const opensans = new FontFaceObserver('Open Sans');
opensans.load()
  .then(() => {
    window.document.documentElement.className += ' open-sans-loaded';
  }).catch((error) => {
    console.log(error);
  });

const valentine = new FontFaceObserver('Valentine');
valentine.load()
  .then(() => {
    window.document.documentElement.className += ' valentine-loaded';
  }).catch((error) => {
    console.log(error);
  });

function requireCss(uri){
  var urid = uri;
  if(uri.indexOf('/') !== -1){
    urid = uri.split('/').slice(-1)[0];
  }
  if(document.querySelector('#'+urid) == null) {
    var link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', uri);
    link.setAttribute('id', urid);
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}
