if (typeof Routing !== 'undefined' && typeof navEnabled !== 'undefined' && navEnabled == 'true') {
    /**
     * Cart Manager
     * @constructor
     */
    var Cart = function () {
        this.$ = window.$;
        this.routing = Routing;

        this.callPopin(currentLanguage, currentMarket);
        this.get(currentLanguage, currentMarket);
        this.getCartQuantityFromNav();
    };


    /**
     * Refresh cart header dom
     *
     * @param data
     */
    Cart.prototype.refreshCartDom = function (data) {
    };

    /**
     * Get current cart header template
     *
     * @return string html
     */
    Cart.prototype.get = function (language, market) {
        if (language == undefined && market == undefined) {
            language = 'fr';
            market = 'FR';
        }
        let current = false;
        if (!current) {
            var ajaxUrl = this.routing.generate('mdm_user_cart_widget', {'language': language, 'market': market});
            this.callAjax(ajaxUrl, null, this.set);
        }
    };

    /**
     * @param data
     */
    Cart.prototype.set = function (data, self) {
    };

    /**
     * Call ajax cart action
     *
     * @param ajaxUrl
     * @param callback
     */
    Cart.prototype.callAjax = function (ajaxUrl, product, callback) {
        var that = this;
        this.$.ajax({
            'url': ajaxUrl,
            'success': function (data) {
                if (typeof(callback) == 'undefined') {
                    that.update(product, data);
                } else {
                    callback(data, that);
                }

            }
        });
    };

    /**
     * Get cart quantity from header cart widget
     */
    Cart.prototype.getCartQuantityFromNav = function () {
        var headerCart = this.$('.header--cart.js span.header--tool-txt');

        if (typeof headerCart !== 'undefined') {
            this.$('span.nav--cart-number').html(headerCart.html());
            this.$('span.nav--cart-number').children().remove();
        }
    };

    Cart.prototype.callPopin = function (language, market) {
        if (language == undefined && market == undefined) {
            language = 'fr';
            market = 'FR';
        }
        var that = this;
        var routing = this.routing;

        $('#form-add-cart').on('submit', function(event) {
            event.preventDefault();

            var reference = $('#add-cart').data('reference');
            var form = $(this).serializeArray();
            var ajaxUrl = routing.generate('mdm_add_cart', {'language': language, 'market': market, 'referenceEncoded': reference, 'quantity' : form[0].value});

            that.$.ajax({
                url: ajaxUrl,
                method: 'GET',
                requestHeaders: {Accept: 'application/json'},
                complete: function (data) {
                    var response = JSON.parse(data.responseText);
                    var product = response.product;
                    var user_data = response.user_data;

                    $('#popinCart').addClass('in').css({"display": "block", "padding-right":"0px"});
                    $('#product-quantity .quantity').html(user_data.product_quantity);
                    $('#eco-part').html(user_data.total_amount_eco_part);
                    $('#ajout-panier-prix').html(user_data.total_amount);
                    $('.header--cart .header--tool-txt').html(user_data.total_quantity);
                }
            });
         });

        $('.modal-header .close').on('click', function(){
            $('#popinCart').removeClass('in').css({"display": "none"});
        });
    };

    var cart = new Cart();
}
